import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { supabase } from './supabaseClient'
import Board from './components/Board'
import Auth from './components/Auth'
import AuthDebug from './components/AuthDebug'
import TestPage from './components/TestPage'
import DatabaseCheck from './components/DatabaseCheck'
import EnvTest from './EnvTest'
import './index.css'

// Auth callback component that redirects to home
const AuthCallback = () => {
  useEffect(() => {
    // Remove hash fragment if present
    if (window.location.hash) {
      const newUrl = window.location.pathname.replace('/auth/callback', '');
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  return <Navigate to="/" />;
};

function App() {
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Check for an existing session
    supabase.auth.getSession().then(({ data: { session }, error }) => {
      if (error) {
        console.error('Error getting session:', error.message)
        setError(error.message)
      }
      setSession(session)
      setLoading(false)
    })

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  // Show error if there's a problem with Supabase initialization
  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen" style={{ backgroundColor: '#eee' }}>
        <div className="text-2xl font-semibold text-red-700 mb-4">Error: {error}</div>
        <EnvTest />
      </div>
    )
  }

  // Show loading indicator
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen" style={{ backgroundColor: '#eee' }}>
        <div className="text-2xl font-semibold text-gray-700">Loading...</div>
      </div>
    )
  }

  return (
    <Router>
      <div className="min-h-screen" style={{ backgroundColor: '#eee' }}>
        <Routes>
          <Route 
            path="/" 
            element={session ? <Board user={session.user} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/login" 
            element={!session ? <Auth /> : <Navigate to="/" />} 
          />
          <Route path="/env-test" element={<EnvTest />} />
          <Route path="/auth-debug" element={<AuthDebug />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/db-check" element={<DatabaseCheck />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
