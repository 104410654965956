import { createClient } from '@supabase/supabase-js';

// Use environment variables for Supabase configuration
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase environment variables!');
}

// Create the Supabase client
let supabaseInstance;
try {
  supabaseInstance = createClient(supabaseUrl, supabaseAnonKey);
  console.log('Supabase client created successfully');
  
  // Store the configuration in localStorage for persistence
  if (typeof window !== 'undefined' && window.localStorage) {
    localStorage.setItem('SUPABASE_CONFIG', JSON.stringify({
      url: supabaseUrl,
      anonKey: supabaseAnonKey
    }));
  }
} catch (error) {
  console.error('Error creating Supabase client:', error);
  // Create a dummy client that won't throw errors but won't work either
  supabaseInstance = {
    auth: {
      getSession: () => Promise.resolve({ data: { session: null }, error: null }),
      onAuthStateChange: () => ({ data: { subscription: { unsubscribe: () => {} } } }),
      signOut: () => Promise.resolve({ error: null }),
      signInWithOAuth: () => Promise.resolve({ error: null }),
      signInWithOtp: () => Promise.resolve({ error: null })
    },
    from: () => ({
      select: () => ({ order: () => Promise.resolve({ data: [], error: null }) }),
      insert: () => Promise.resolve({ data: null, error: null }),
      update: () => Promise.resolve({ data: null, error: null }),
      delete: () => Promise.resolve({ data: null, error: null })
    })
  };
}

export const supabase = supabaseInstance;
