import React from 'react';

export default function TestPage() {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md mt-10">
      <h1 className="text-2xl font-bold mb-4">Test Page</h1>
      <p>This is a simple test page to verify routing is working correctly.</p>
      <p>Current URL: {window.location.href}</p>
    </div>
  );
} 