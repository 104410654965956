import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

/**
 * Background component that renders a background image and overlay using React portals
 * This ensures the background is rendered at the document root level
 */
const Background = ({ imageUrl }) => {
  const [backgroundRoot, setBackgroundRoot] = useState(null);
  
  // Debug log for imageUrl
  console.log('Background component received imageUrl:', imageUrl);
  
  useEffect(() => {
    // Create or get the background container
    let container = document.getElementById('background-container');
    if (!container) {
      container = document.createElement('div');
      container.id = 'background-container';
      container.style.position = 'fixed';
      container.style.top = '0';
      container.style.left = '0';
      container.style.width = '100%';
      container.style.height = '100%';
      container.style.zIndex = '1';
      container.style.pointerEvents = 'none';
      document.body.prepend(container);
      console.log('Created background container:', container);
    } else {
      console.log('Found existing background container');
    }
    
    setBackgroundRoot(container);
    
    // Clean up function
    return () => {
      if (container && container.parentNode && !imageUrl) {
        container.parentNode.removeChild(container);
        console.log('Removed background container');
      }
    };
  }, [imageUrl]);
  
  // Don't render anything if there's no background root or image URL
  if (!backgroundRoot) {
    console.log('No background root, not rendering');
    return null;
  }
  
  if (!imageUrl) {
    console.log('No image URL, not rendering background elements');
    return null;
  }
  
  console.log('Rendering background with image:', imageUrl);
  
  // Create the background and overlay elements
  const backgroundElement = (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        zIndex: 1
      }}
      aria-hidden="true"
    />
  );
  
  const overlayElement = (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        zIndex: 2
      }}
      aria-hidden="true"
    />
  );
  
  // Use createPortal to render the background and overlay at the document root level
  return (
    <>
      {createPortal(backgroundElement, backgroundRoot)}
      {createPortal(overlayElement, backgroundRoot)}
    </>
  );
};

export default Background; 