import { supabase } from '../supabaseClient';

// Lists operations
export const getLists = async () => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return [];
  }
  
  const { data, error } = await supabase
    .from('lists')
    .select('*')
    .eq('user_id', userId)
    .order('position');
  
  if (error) {
    console.error('Error fetching lists:', error);
    return [];
  }
  
  // Transform from snake_case to camelCase for frontend
  return data.map(list => ({
    id: list.id,
    title: list.title,
    position: list.position,
    backgroundColor: list.background_color || '#F5F5F5',
    color: list.background_color || '#F5F5F5', // For backward compatibility
    fontColor: list.font_color || '#333333',
    userId: list.user_id,
    user_id: list.user_id // For backward compatibility
  })) || [];
};

export const saveList = async (list) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return null;
  }
  
  // Get current lists to determine position if not provided
  if (typeof list.position !== 'number') {
    const { data: existingLists } = await supabase
      .from('lists')
      .select('position')
      .eq('user_id', userId)
      .order('position', { ascending: false })
      .limit(1);
    
    list.position = existingLists?.length ? (existingLists[0].position + 1) : 0;
  }
  
  // Prepare list data with standardized property names
  const listData = {
    title: list.title,
    position: list.position,
    background_color: list.backgroundColor || list.color || '#F5F5F5',
    font_color: list.fontColor || '#333333',
    user_id: userId
  };
  
  // If list has an ID, include it for update
  if (list.id) {
    listData.id = list.id;
  }
  
  const { data, error } = await supabase
    .from('lists')
    .upsert(listData)
    .select()
    .single();
  
  if (error) {
    console.error('Error saving list:', error);
    return null;
  }
  
  // Transform from snake_case to camelCase for frontend
  return {
    id: data.id,
    title: data.title,
    position: data.position,
    backgroundColor: data.background_color || '#F5F5F5',
    color: data.background_color || '#F5F5F5', // For backward compatibility
    fontColor: data.font_color || '#333333',
    userId: data.user_id,
    user_id: data.user_id // For backward compatibility
  };
};

export const deleteList = async (listId) => {
  const { error } = await supabase
    .from('lists')
    .delete()
    .eq('id', listId);
  
  if (error) {
    console.error('Error deleting list:', error);
    return false;
  }
  
  return true;
};

export const updateListPositions = async (lists) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return false;
  }

  // Prepare updates with all required fields
  const updates = lists.map((list) => ({
    id: list.id,
    position: list.position,
    user_id: userId,
    title: list.title,
    background_color: list.backgroundColor || list.color || '#F5F5F5',
    font_color: list.fontColor || '#333333'
  }));
  
  const { error } = await supabase
    .from('lists')
    .upsert(updates, {
      onConflict: 'id',
      ignoreDuplicates: false
    });
  
  if (error) {
    console.error('Error updating list positions:', error);
    return false;
  }
  
  return true;
};

// Cards operations
export const getCards = async () => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return [];
  }
  
  const { data, error } = await supabase
    .from('cards')
    .select('*')
    .eq('user_id', userId)
    .order('position');
  
  if (error) {
    console.error('Error fetching cards:', error);
    return [];
  }
  
  // Transform from snake_case to camelCase for frontend
  return data.map(card => ({
    id: card.id,
    content: card.content,
    position: card.position,
    listId: card.list_id,
    list_id: card.list_id, // For backward compatibility
    userId: card.user_id,
    user_id: card.user_id, // For backward compatibility
    deadline: card.deadline, // Add deadline field
    isDone: card.is_done || false // Add isDone field
  })) || [];
};

export const getCardsByListId = async (listId) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return [];
  }
  
  const { data, error } = await supabase
    .from('cards')
    .select('*')
    .eq('list_id', listId)
    .eq('user_id', userId)
    .order('position');
  
  if (error) {
    console.error('Error fetching cards by list ID:', error);
    return [];
  }
  
  // Transform from snake_case to camelCase for frontend
  return data.map(card => ({
    id: card.id,
    content: card.content,
    position: card.position,
    listId: card.list_id,
    list_id: card.list_id, // For backward compatibility
    userId: card.user_id,
    user_id: card.user_id, // For backward compatibility
    deadline: card.deadline, // Add deadline field
    isDone: card.is_done || false // Add isDone field
  })) || [];
};

export const saveCard = async (card) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return null;
  }
  
  // Get current cards to determine position if not provided
  if (typeof card.position !== 'number') {
    const { data: existingCards } = await supabase
      .from('cards')
      .select('position')
      .eq('list_id', card.listId || card.list_id)
      .order('position', { ascending: false })
      .limit(1);
    
    card.position = existingCards?.length ? (existingCards[0].position + 1) : 0;
  }
  
  // Prepare card data with standardized property names
  const cardData = {
    content: card.content,
    position: card.position,
    list_id: (card.listId || card.list_id).toString(), // Ensure consistent string format
    user_id: userId
  };
  
  // Include deadline if it exists
  if (card.deadline !== undefined) {
    cardData.deadline = card.deadline;
  }
  
  // Include isDone if it exists
  if (card.isDone !== undefined) {
    cardData.is_done = card.isDone;
  }
  
  // If card has an ID, include it for update
  if (card.id) {
    cardData.id = card.id;
  }
  
  const { data, error } = await supabase
    .from('cards')
    .upsert(cardData)
    .select()
    .single();
  
  if (error) {
    console.error('Error saving card:', error);
    return null;
  }
  
  // Transform from snake_case to camelCase for frontend
  return {
    id: data.id,
    content: data.content,
    position: data.position,
    listId: data.list_id.toString(), // Ensure consistent string format
    list_id: data.list_id.toString(), // For backward compatibility
    userId: data.user_id,
    user_id: data.user_id, // For backward compatibility
    deadline: data.deadline // Add deadline field
  };
};

export const deleteCard = async (cardId) => {
  const { error } = await supabase
    .from('cards')
    .delete()
    .eq('id', cardId);
  
  if (error) {
    console.error('Error deleting card:', error);
    return false;
  }
  
  return true;
};

export const updateCardPositions = async (cards) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return false;
  }

  // Prepare updates with all required fields
  const updates = cards.map((card) => ({
    id: card.id,
    position: card.position,
    list_id: card.listId || card.list_id, // Include list_id in updates
    user_id: userId,
    content: card.content,
    deadline: card.deadline // Include deadline in updates
  }));
  
  const { error } = await supabase
    .from('cards')
    .upsert(updates, {
      onConflict: 'id',
      ignoreDuplicates: false
    });
  
  if (error) {
    console.error('Error updating card positions:', error);
    return false;
  }
  
  return true;
};

// Settings operations
export const getSettings = async () => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return createDefaultSettings();
  }
  
  console.log('Fetching settings for user:', userId);
  
  // Ensure the board_name column exists
  await ensureBoardNameColumn();
  
  // Ensure the lists_per_row column exists
  await ensureListsPerRowColumn();
  
  const { data, error } = await supabase
    .from('user_settings')
    .select('*')
    .eq('user_id', userId)
    .single();
  
  if (error && error.code !== 'PGRST116') { // PGRST116 is "no rows returned" error
    console.error('Error fetching settings:', error);
    return createDefaultSettings();
  }
  
  if (!data) {
    console.log('No settings found, creating default settings');
    // Create default settings for new user
    const defaultSettings = createDefaultSettings();
    await saveSettings(defaultSettings);
    return defaultSettings;
  }
  
  console.log('Retrieved settings from database:', data);
  
  // Transform from snake_case to camelCase for frontend
  const settings = {
    backgroundColor: data.background_color,
    fontSize: data.font_size,
    fontWeight: data.font_weight,
    shadowDepth: data.shadow_depth,
    borderRadius: data.border_radius,
    cardPadding: data.card_padding,
    listPadding: data.list_padding,
    backgroundImageUrl: data.background_image_url || null,
    boardName: data.board_name || 'My Board',
    listsPerRow: data.lists_per_row || 5
  };
  
  console.log('Transformed settings for frontend:', settings);
  return settings;
};

// Ensure the board_name column exists in the user_settings table
const ensureBoardNameColumn = async () => {
  try {
    // Try to use the add_board_name_column function
    const { error: rpcError } = await supabase.rpc('add_board_name_column');
    
    if (rpcError) {
      console.error('Error using add_board_name_column RPC:', rpcError);
      
      // Fallback to using execute_sql
      try {
        // Check if the column exists first
        const { data: columnExists, error: checkError } = await supabase.rpc(
          'execute_sql',
          { 
            sql_query: `
              SELECT to_json(EXISTS (
                SELECT FROM information_schema.columns 
                WHERE table_schema = 'public'
                AND table_name = 'user_settings'
                AND column_name = 'board_name'
              )) as exists;
            `
          }
        );
        
        if (checkError) {
          console.error('Error checking if board_name column exists:', checkError);
          return;
        }
        
        // Add the column if it doesn't exist
        if (!columnExists || !columnExists[0] || !columnExists[0].exists) {
          const { error: sqlError } = await supabase.rpc(
            'execute_sql',
            { 
              sql_query: `
                ALTER TABLE user_settings 
                ADD COLUMN IF NOT EXISTS board_name TEXT DEFAULT 'My Board';
                SELECT to_json('Column added') as result;
              `
            }
          );
          
          if (sqlError) {
            console.error('Error adding board_name column:', sqlError);
          }
        }
      } catch (error) {
        console.error('Error using execute_sql fallback:', error);
      }
    }
  } catch (error) {
    console.error('Exception in ensureBoardNameColumn:', error);
  }
};

// Ensure the lists_per_row column exists in the user_settings table
const ensureListsPerRowColumn = async () => {
  try {
    // First approach: Try adding the column directly
    // This might fail if the column already exists, but that's okay
    try {
      await supabase.from('user_settings').select('lists_per_row').limit(1);
      console.log('lists_per_row column exists (verified by query)');
      return; // Column exists, we're done
    } catch (error) {
      // Column doesn't exist in the schema cache, need to create it
      console.log('lists_per_row column may not exist, attempting to create it');
    }

    // Since we can't directly run ALTER TABLE statements through REST API,
    // we'll create a new row with the lists_per_row field which will force
    // Supabase to recognize the column
    
    // Get current user
    const { data: { user } } = await supabase.auth.getUser();
    const userId = user?.id;
    
    if (!userId) {
      console.error('No authenticated user found');
      return;
    }
    
    // Rather than trying to alter the table directly, we'll create a dummy entry
    // that will effectively create the column through the schema cache
    const dummySettings = {
      user_id: userId,
      lists_per_row: 5
    };
    
    // Try to insert/update the dummy settings
    // This may fail if the user already has settings, but will refresh the schema cache
    const { error } = await supabase
      .from('user_settings')
      .upsert(dummySettings, {
        onConflict: 'user_id',
        ignoreDuplicates: true
      });
    
    if (error && error.code !== 'PGRST204') {
      // If it's not a schema cache error, log it
      console.log('Initial attempt to create lists_per_row via upsert:', error);
    } else {
      console.log('Successfully created or verified lists_per_row column');
    }
  } catch (error) {
    console.error('Exception in ensureListsPerRowColumn:', error);
  }
};

export const saveSettings = async (settings) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return null;
  }
  
  try {
    console.log('Saving settings for user:', userId);
    console.log('Settings to save:', settings);
    
    // Ensure the board_name column exists
    await ensureBoardNameColumn();
    
    // Ensure the lists_per_row column exists
    await ensureListsPerRowColumn();
    
    const settingsData = {
      user_id: userId,
      background_color: settings.backgroundColor,
      font_size: settings.fontSize,
      font_weight: settings.fontWeight,
      shadow_depth: settings.shadowDepth,
      border_radius: settings.borderRadius,
      card_padding: settings.cardPadding,
      list_padding: settings.listPadding,
      background_image_url: settings.backgroundImageUrl || null,
      board_name: settings.boardName || 'My Board',
      lists_per_row: settings.listsPerRow || 5
    };
    
    console.log('Formatted settings data:', settingsData);
    
    let data; // Changed from const to let to allow reassignment
    const { data: initialData, error } = await supabase
      .from('user_settings')
      .upsert(settingsData, {
        onConflict: 'user_id',
        ignoreDuplicates: false
      })
      .select()
      .single();
    
    data = initialData; // Assign the initial data
    
    if (error) {
      console.error('Error saving settings:', error);
      
      // Try to diagnose the issue
      if (error.code === '42P01') {
        console.error('Table "user_settings" does not exist. Attempting to create it...');
        
        // Try to create the table
        const { error: createError } = await supabase.rpc('create_user_settings_table');
        if (createError) {
          console.error('Failed to create user_settings table:', createError);
          return null;
        }
        
        // Try saving again
        const { data: retryData, error: retryError } = await supabase
          .from('user_settings')
          .upsert(settingsData, {
            onConflict: 'user_id',
            ignoreDuplicates: false
          })
          .select()
          .single();
          
        if (retryError) {
          console.error('Error saving settings after table creation:', retryError);
          return null;
        }
        
        data = retryData;
      } else {
        return null;
      }
    }
    
    console.log('Settings saved successfully:', data);
    
    // Transform from snake_case to camelCase for frontend
    return {
      backgroundColor: data.background_color,
      fontSize: data.font_size,
      fontWeight: data.font_weight,
      shadowDepth: data.shadow_depth,
      borderRadius: data.border_radius,
      cardPadding: data.card_padding,
      listPadding: data.list_padding,
      backgroundImageUrl: data.background_image_url || null,
      boardName: data.board_name || 'My Board',
      listsPerRow: data.lists_per_row || 5
    };
  } catch (error) {
    console.error('Exception in saveSettings:', error);
    return null;
  }
};

const createDefaultSettings = () => {
  return {
    backgroundColor: '#DDDDDD',
    fontSize: 18,
    fontWeight: 500,
    shadowDepth: 2,
    borderRadius: 6,
    cardPadding: 10,
    listPadding: 8,
    backgroundImageUrl: null,
    boardName: 'My Board',
    listsPerRow: 5
  };
};

// Background image operations
export const uploadBackgroundImage = async (file) => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return null;
  }
  
  console.log('Uploading background image for user:', userId);
  
  // Validate file size (5MB limit)
  if (file.size > 5 * 1024 * 1024) {
    throw new Error('File size exceeds 5MB limit');
  }
  
  // Generate a unique file name
  const fileExt = file.name.split('.').pop();
  const fileName = `${userId}-${Date.now()}.${fileExt}`;
  const filePath = `backgrounds/${fileName}`;
  
  console.log('Uploading file to path:', filePath);
  
  // Upload file to Supabase Storage
  const { data, error } = await supabase.storage
    .from('user_backgrounds')
    .upload(filePath, file, {
      cacheControl: '3600',
      upsert: false
    });
  
  if (error) {
    console.error('Error uploading background image:', error);
    throw error;
  }
  
  console.log('File uploaded successfully:', data);
  
  // Get the public URL for the uploaded file
  const { data: { publicUrl } } = supabase.storage
    .from('user_backgrounds')
    .getPublicUrl(filePath);
  
  console.log('Generated public URL:', publicUrl);
  
  // Update user settings with the new background image URL
  const settings = await getSettings();
  settings.backgroundImageUrl = publicUrl;
  await saveSettings(settings);
  
  console.log('Updated settings with new background image URL');
  
  return publicUrl;
};

export const deleteBackgroundImage = async () => {
  // Get current user
  const { data: { user } } = await supabase.auth.getUser();
  const userId = user?.id;
  
  if (!userId) {
    console.error('No authenticated user found');
    return false;
  }
  
  // Get current settings
  const settings = await getSettings();
  
  // If there's no background image, nothing to delete
  if (!settings.backgroundImageUrl) {
    return true;
  }
  
  // Extract the file path from the URL
  const url = new URL(settings.backgroundImageUrl);
  const pathParts = url.pathname.split('/');
  const filePath = pathParts.slice(pathParts.indexOf('user_backgrounds') + 1).join('/');
  
  if (filePath) {
    // Delete the file from storage
    const { error } = await supabase.storage
      .from('user_backgrounds')
      .remove([filePath]);
    
    if (error) {
      console.error('Error deleting background image:', error);
      return false;
    }
  }
  
  // Update settings to remove the background image URL
  settings.backgroundImageUrl = null;
  await saveSettings(settings);
  
  return true;
};
