import { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { supabase } from '../supabaseClient';
import List from './List';
import AddListForm from './AddListForm';
import Background from './Background';
import * as storage from '../utils/storageAdapter';
import * as realtime from '../utils/realtimeSubscriptions';

export default function Board({ user }) {
  const [lists, setLists] = useState([]);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [settings, setSettings] = useState({
    backgroundColor: '#DDDDDD',
    fontSize: 18,
    fontWeight: 500,
    shadowDepth: 2,
    borderRadius: 6,
    cardPadding: 10,
    listPadding: 8,
    backgroundImageUrl: null,
    boardName: 'My Board',
    listsPerRow: 5
  });
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('idle'); // 'idle', 'uploading', 'success', 'error'
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');
  const settingsPanelRef = useRef(null);
  const fileInputRef = useRef(null);

  // Load data on component mount
  useEffect(() => {
    // Fetch data
    fetchData();
  }, []);

  // Fetch all data from storage
  const fetchData = async () => {
    setLoading(true);
    try {
      // Fetch lists
      const listsData = await storage.getLists();
      
      // Fetch cards
      const cardsData = await storage.getCards();
      
      // Organize cards by list
      const listsWithCards = listsData.map(list => {
        // Check both listId and list_id for backward compatibility
        const listCards = cardsData.filter(card => 
          (card.listId && card.listId.toString() === list.id.toString()) || 
          (card.list_id && card.list_id.toString() === list.id.toString())
        );
        return {
          ...list,
          cards: listCards
        };
      });
      
      setLists(listsWithCards);
      
      // Fetch settings
      const settingsData = await storage.getSettings();
      if (settingsData) {
        setSettings(settingsData);
        // Set image preview if there's a background image
        if (settingsData.backgroundImageUrl) {
          setImagePreview(settingsData.backgroundImageUrl);
        }
      }
      
      console.log('Fetched data:', { listsWithCards, settingsData });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = async (result) => {
    const { destination, source, type } = result;

    // If there's no destination or the item was dropped in its original position
    if (!destination) {
      return;
    }

    // Handle list reordering
    if (type === 'list') {
      // Parse the source and destination indices from the droppable IDs
      const sourceIndex = parseInt(source.droppableId.split('-')[1]);
      const destIndex = parseInt(destination.droppableId.split('-')[1]);
      
      // If dropped in the same position, do nothing
      if (sourceIndex === destIndex) {
        return;
      }
      
      const newLists = Array.from(lists);
      const [movedList] = newLists.splice(sourceIndex, 1);
      newLists.splice(destIndex, 0, movedList);
      
      // Update positions
      const updatedLists = newLists.map((list, index) => ({
        ...list,
        position: index,
        backgroundColor: list.backgroundColor || list.color || '#F5F5F5',
        color: list.backgroundColor || list.color || '#F5F5F5', // For backward compatibility
        fontColor: list.fontColor || '#333333'
      }));
      
      // Update state immediately for responsive UI
      setLists(updatedLists);
      
      try {
        // Update in storage
        const success = await storage.updateListPositions(updatedLists);
        if (!success) {
          // Revert state on failure
          setLists(lists);
          console.error('Failed to save list positions');
        }
      } catch (error) {
        // Revert state on error
        setLists(lists);
        console.error('Error saving list positions:', error);
      }
      return;
    }

    // Handle card reordering
    const sourceListIndex = lists.findIndex(list => list.id.toString() === source.droppableId);
    const destListIndex = lists.findIndex(list => list.id.toString() === destination.droppableId);
    
    if (sourceListIndex === -1 || destListIndex === -1) return;
    
    const newLists = Array.from(lists);
    const sourceCards = Array.from(newLists[sourceListIndex].cards);
    const [movedCard] = sourceCards.splice(source.index, 1);
    
    // Update the list_id of the moved card
    const updatedMovedCard = {
      ...movedCard,
      listId: destination.droppableId.toString(), // Ensure consistent string format
      list_id: destination.droppableId.toString() // For backward compatibility
    };
    
    // Moving within the same list
    if (source.droppableId === destination.droppableId) {
      sourceCards.splice(destination.index, 0, updatedMovedCard);
      
      // Update positions
      const updatedCards = sourceCards.map((card, index) => ({
        ...card,
        position: index,
        listId: source.droppableId.toString(),
        list_id: source.droppableId.toString() // For backward compatibility
      }));
      
      // Update state immediately for responsive UI
      newLists[sourceListIndex].cards = updatedCards;
      setLists(newLists);
      
      try {
        // Update in storage
        const success = await storage.updateCardPositions(updatedCards);
        if (!success) {
          // Revert state on failure
          const revertedLists = [...lists];
          setLists(revertedLists);
          console.error('Failed to save card positions');
        }
      } catch (error) {
        // Revert state on error
        const revertedLists = [...lists];
        setLists(revertedLists);
        console.error('Error saving card positions:', error);
      }
    } 
    // Moving to a different list
    else {
      const destCards = Array.from(newLists[destListIndex].cards);
      destCards.splice(destination.index, 0, updatedMovedCard);
      
      // Update positions for both lists
      const updatedSourceCards = sourceCards.map((card, index) => ({
        ...card,
        position: index,
        listId: source.droppableId.toString(),
        list_id: source.droppableId.toString() // For backward compatibility
      }));
      
      const updatedDestCards = destCards.map((card, index) => ({
        ...card,
        position: index,
        listId: destination.droppableId.toString(),
        list_id: destination.droppableId.toString() // For backward compatibility
      }));
      
      // Update state immediately for responsive UI
      newLists[sourceListIndex].cards = updatedSourceCards;
      newLists[destListIndex].cards = updatedDestCards;
      setLists(newLists);
      
      try {
        // First save the moved card with its new list ID
        const savedCard = await storage.saveCard(updatedMovedCard);
        if (!savedCard) {
          throw new Error('Failed to save moved card');
        }
        
        // Then update positions for all affected cards
        const success = await storage.updateCardPositions([...updatedSourceCards, ...updatedDestCards]);
        if (!success) {
          throw new Error('Failed to update card positions');
        }
      } catch (error) {
        // Revert state on error
        const revertedLists = [...lists];
        setLists(revertedLists);
        console.error('Error moving card between lists:', error);
      }
    }
  };

  const addCard = async (content, listId) => {
    if (!content.trim()) return;
    
    try {
      const listIndex = lists.findIndex(list => list.id.toString() === listId.toString());
      if (listIndex === -1) {
        throw new Error('List not found');
      }
      
      // Create new card with standardized property names
      const newCard = {
        content,
        listId: listId.toString(), // Ensure consistent string format
        list_id: listId.toString(), // For backward compatibility
        userId: user.id,
        user_id: user.id // For backward compatibility
      };
      
      // Save to storage
      const savedCard = await storage.saveCard(newCard);
      if (!savedCard) {
        throw new Error('Failed to save card');
      }
      
      // Update state
      const newLists = [...lists];
      newLists[listIndex].cards.push(savedCard);
      setLists(newLists);
      
    } catch (error) {
      console.error('Error adding card:', error);
      alert('Error adding card: ' + error.message);
    }
  };

  const addList = async (title) => {
    if (!title.trim()) return;
    
    try {
      const newPosition = lists.length;
      
      // Create new list with standardized property names
      const newList = { 
        title, 
        position: newPosition,
        backgroundColor: '#DEDEDE', // Default background color
        color: '#DEDEDE', // For backward compatibility
        fontColor: '#333333', // Default font color
        userId: user.id,
        user_id: user.id // For backward compatibility
      };
      
      // Save to storage
      const savedList = await storage.saveList(newList);
      if (!savedList) {
        throw new Error('Failed to save list');
      }
      
      // Add the new list to state with an empty cards array
      setLists([...lists, { ...savedList, cards: [] }]);
      
    } catch (error) {
      console.error('Error adding list:', error);
      alert('Error adding list');
    }
  };

  const deleteListHandler = async (listId) => {
    try {
      // Delete from storage
      await storage.deleteList(listId);
      
      // Update state
      setLists(lists.filter(list => list.id !== listId));
      
    } catch (error) {
      console.error('Error deleting list:', error);
      alert('Error deleting list');
    }
  };

  const deleteCardHandler = async (cardId, listId) => {
    try {
      // Delete from storage
      await storage.deleteCard(cardId);
      
      // Update state
      const listIndex = lists.findIndex(list => list.id === listId);
      if (listIndex === -1) return;
      
      const newLists = [...lists];
      newLists[listIndex].cards = newLists[listIndex].cards.filter(card => card.id !== cardId);
      setLists(newLists);
      
    } catch (error) {
      console.error('Error deleting card:', error);
      alert('Error deleting card');
    }
  };

  const updateCardContent = async (cardId, listId, newContent, additionalProps = {}) => {
    try {
      if (!newContent.trim()) return;
      
      // Find the card
      const listIndex = lists.findIndex(list => list.id === listId);
      if (listIndex === -1) return;
      
      const cardIndex = lists[listIndex].cards.findIndex(card => card.id === cardId);
      if (cardIndex === -1) return;
      
      // Update card in state
      const newLists = [...lists];
      const updatedCard = { 
        ...newLists[listIndex].cards[cardIndex], 
        content: newContent,
        ...additionalProps // Add any additional properties like isDone
      };
      newLists[listIndex].cards[cardIndex] = updatedCard;
      
      // Save to storage
      await storage.saveCard(updatedCard);
      
      // Update state
      setLists(newLists);
      
    } catch (error) {
      console.error('Error updating card:', error);
      alert('Error updating card');
    }
  };

  const updateCardDeadline = async (cardId, listId, deadline) => {
    try {
      // Find the card
      const listIndex = lists.findIndex(list => list.id === listId);
      if (listIndex === -1) return;
      
      const cardIndex = lists[listIndex].cards.findIndex(card => card.id === cardId);
      if (cardIndex === -1) return;
      
      // Update card in state
      const newLists = [...lists];
      const updatedCard = { ...newLists[listIndex].cards[cardIndex], deadline };
      newLists[listIndex].cards[cardIndex] = updatedCard;
      
      // Save to storage
      await storage.saveCard(updatedCard);
      
      // Update state
      setLists(newLists);
      
    } catch (error) {
      console.error('Error updating card deadline:', error);
      alert('Error updating card deadline');
    }
  };

  const updateListTitle = async (listId, newTitle) => {
    try {
      const listIndex = lists.findIndex(list => list.id === listId);
      if (listIndex === -1) return;
      
      // Update in storage
      const updatedList = { ...lists[listIndex], title: newTitle };
      await storage.saveList(updatedList);
      
      // Update state
      const newLists = [...lists];
      newLists[listIndex].title = newTitle;
      setLists(newLists);
      
    } catch (error) {
      console.error('Error updating list title:', error);
      alert('Error updating list title');
    }
  };

  const updateListColor = async (listId, newColor, newFontColor) => {
    try {
      const listIndex = lists.findIndex(list => list.id === listId);
      if (listIndex === -1) return;
      
      // Ensure we have valid colors
      const backgroundColor = newColor || '#DEDEDE';
      const fontColor = newFontColor || '#333333';
      
      // Update state immediately for responsive UI
      const newLists = [...lists];
      const updatedList = {
        ...newLists[listIndex],
        backgroundColor,
        color: backgroundColor, // For backward compatibility
        fontColor
      };
      newLists[listIndex] = updatedList;
      setLists(newLists);
      
      // Update in storage (async operation)
      await storage.saveList(updatedList).catch(error => {
        console.error('Error saving list color to storage:', error);
        // Revert state on error
        const revertedLists = [...lists];
        revertedLists[listIndex] = lists[listIndex];
        setLists(revertedLists);
      });
      
    } catch (error) {
      console.error('Error updating list color:', error);
      alert('Error updating list color');
    }
  };

  // Handle background image file selection
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Set the selected filename
    setSelectedFileName(file.name);
    
    // Validate file type
    const validTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      setErrorMessage('Please select a valid image file (JPEG, PNG, GIF, WEBP)');
      setUploadStatus('error');
      return;
    }
    
    // Validate file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage('Image size exceeds 5MB limit');
      setUploadStatus('error');
      return;
    }
    
    // Create preview
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagePreview(e.target.result);
    };
    reader.readAsDataURL(file);
    
    // Upload file
    try {
      setUploadStatus('uploading');
      setErrorMessage('');
      
      const publicUrl = await storage.uploadBackgroundImage(file);
      
      // Update settings
      const updatedSettings = {
        ...settings,
        backgroundImageUrl: publicUrl
      };
      setSettings(updatedSettings);
      setUploadStatus('success');
      
      // Clear file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setErrorMessage(error.message || 'Error uploading image');
      setUploadStatus('error');
    }
  };
  
  // Handle background image removal
  const handleRemoveImage = async () => {
    try {
      setUploadStatus('uploading');
      setErrorMessage('');
      
      await storage.deleteBackgroundImage();
      
      // Update settings
      const updatedSettings = {
        ...settings,
        backgroundImageUrl: null
      };
      setSettings(updatedSettings);
      setImagePreview(null);
      setSelectedFileName('');
      setUploadStatus('idle');
      
      // Clear file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      console.error('Error removing image:', error);
      setErrorMessage(error.message || 'Error removing image');
      setUploadStatus('error');
    }
  };

  // Apply settings
  const handleSettingChange = async (setting, value) => {
    const updatedSettings = {
      ...settings,
      [setting]: value
    };
    setSettings(updatedSettings);
    await storage.saveSettings(updatedSettings);
  };

  // Reset settings to default values
  const resetSettings = async () => {
    const defaultSettings = {
      backgroundColor: '#d4d4d4',
      fontSize: 15,
      fontWeight: 400,
      shadowDepth: 2,
      borderRadius: 4,
      cardPadding: 8,
      listPadding: 8,
      backgroundImageUrl: null,
      boardName: 'My Board',
      listsPerRow: 5
    };
    setSettings(defaultSettings);
    setImagePreview(null);
    await storage.saveSettings(defaultSettings);
  };

  // Close settings panel when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (settingsPanelRef.current && !settingsPanelRef.current.contains(event.target)) {
        setIsSettingsOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error) {
      console.error('Error logging out:', error.message);
      alert('Error logging out. Please try again.');
    }
  };

  // Calculate styles based on settings
  const getFontSizeClass = () => {
    const size = settings.fontSize || 18;
    // Using inline style instead of dynamic Tailwind class
    return { fontSize: `${size}px` };
  };

  const getFontWeightClass = () => {
    const weight = settings.fontWeight || 500;
    // Using inline style instead of dynamic Tailwind class
    return { fontWeight: weight };
  };

  const getShadowClass = () => {
    const depth = settings.shadowDepth || 2;
    return `shadow-${depth === 1 ? 'sm' : depth === 2 ? 'md' : depth === 3 ? 'lg' : depth === 4 ? 'xl' : '2xl'}`;
  };

  // Get container style
  const getContainerStyle = () => {
    console.log('Current settings:', settings);
    return {
      backgroundColor: settings.backgroundImageUrl ? 'transparent' : (settings.backgroundColor || '#DDDDDD'),
      position: 'relative',
      zIndex: 100, // Updated z-index for Board
      minHeight: '100vh'
    };
  };

  // Calculate the board container width based on lists per row
  const getBoardContainerStyle = () => {
    // Each list is 250px wide + 8px gap between lists
    const listWidth = 250;
    const listGap = 8;
    // Adjust calculation to account for the gap correctly
    // We need width for N lists and (N-1) gaps between them, plus extra padding
    const containerWidth = (settings.listsPerRow * listWidth) + ((settings.listsPerRow - 1) * listGap) + 100;
    
    return {
      width: `${containerWidth}px`,
      margin: '0' // Remove auto margin to prevent centering
    };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-xl font-semibold text-gray-700">Loading board...</div>
      </div>
    );
  }

  console.log('Board rendering with backgroundImageUrl:', settings.backgroundImageUrl);

  return (
    <>
      {/* Background component using React portal */}
      <Background imageUrl={settings.backgroundImageUrl} />
      
      {/* Main content */}
      <div className="p-4" style={getContainerStyle()}>
        {/* Header with user info and logout */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <img 
              src={settings.backgroundImageUrl ? "/weblogo-w.png" : "/weblogo.png"} 
              alt="FocusCards" 
              className="h-8 mr-4" 
            />
            <span className={`text-xl font-semibold ${settings.backgroundImageUrl ? 'text-white' : 'text-gray-600'}`}>
              {settings.boardName || 'My Board'}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            {user && (
              <>
                <button
                  onClick={async () => await supabase.auth.signOut()}
                  className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 opacity-50 hover:opacity-100"
                  title="Logout"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </button>
                <button
                  onClick={() => setIsSettingsOpen(true)}
                  className="p-2 bg-white rounded-full shadow-md hover:shadow-lg transition-all duration-300 opacity-50 hover:opacity-100"
                  title="Settings"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </button>
              </>
            )}
          </div>
        </div>

        {/* Settings panel */}
        {isSettingsOpen && (
          <div 
            ref={settingsPanelRef}
            className="fixed top-0 right-0 h-full w-80 bg-white shadow-lg transform transition-transform duration-300 z-[2000] overflow-y-auto"
            style={{ borderLeft: '1px solid #ccc' }}
          >
            <div className="p-4">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-medium">Settings</h2>
                <button 
                  onClick={() => setIsSettingsOpen(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              
              {/* Board Name */}
              <div className="mb-6">
                <h3 className="text-lg font-medium mb-2">Board Name</h3>
                <input
                  type="text"
                  value={settings.boardName || 'My Board'}
                  onChange={(e) => handleSettingChange('boardName', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="Enter board name"
                />
              </div>
              
              {/* Background Image */}
              <div className="mb-6">
                <h3 className="text-lg font-medium mb-2">Background Image</h3>
                
                {/* Image preview */}
                {imagePreview && (
                  <div className="mb-4">
                    <div 
                      className="w-full h-32 bg-cover bg-center rounded mb-2"
                      style={{ backgroundImage: `url(${imagePreview})` }}
                    ></div>
                    <button
                      onClick={handleRemoveImage}
                      className="text-red-500 text-sm hover:text-red-700"
                      disabled={uploadStatus === 'uploading'}
                    >
                      Remove image
                    </button>
                  </div>
                )}
                
                {/* Upload status and error message */}
                {uploadStatus === 'uploading' && (
                  <div className="mb-2 text-blue-500">Uploading...</div>
                )}
                {uploadStatus === 'success' && (
                  <div className="mb-2 text-green-500">Upload successful!</div>
                )}
                {uploadStatus === 'error' && (
                  <div className="mb-2 text-red-500">{errorMessage}</div>
                )}
                
                {/* File input */}
                <div className="flex flex-col">
                  <label 
                    htmlFor="background-image" 
                    className="mb-2 text-sm text-gray-600"
                  >
                    Upload an image (max 5MB)
                  </label>
                  <div className="flex items-center">
                    <label
                      htmlFor="background-image"
                      className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors font-medium cursor-pointer"
                    >
                      Choose File
                    </label>
                    <span className="ml-3 text-sm text-gray-500">
                      {selectedFileName ? selectedFileName : "No file selected"}
                    </span>
                    <input
                      ref={fileInputRef}
                      type="file"
                      id="background-image"
                      accept="image/jpeg, image/png, image/gif, image/webp"
                      onChange={handleFileChange}
                      className="hidden"
                      disabled={uploadStatus === 'uploading'}
                    />
                  </div>
                </div>
              </div>
              
              {/* Lists Per Row */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Lists Per Row</h3>
                  <span className="text-sm text-gray-500">{settings.listsPerRow}</span>
                </div>
                <select
                  value={settings.listsPerRow}
                  onChange={(e) => handleSettingChange('listsPerRow', parseInt(e.target.value))}
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  {[3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                    <option key={num} value={num}>{num}</option>
                  ))}
                </select>
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Fewer lists</span>
                  <span>More lists</span>
                </div>
              </div>
              
              {/* Background Color */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Background Color</h3>
                  <span className="text-sm text-gray-500">{settings.backgroundColor}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <input
                    type="color"
                    value={settings.backgroundColor}
                    onChange={(e) => handleSettingChange('backgroundColor', e.target.value)}
                    className="w-full h-10 rounded cursor-pointer"
                  />
                </div>
              </div>
              
              {/* Font Size */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Font Size</h3>
                  <span className="text-sm text-gray-500">{settings.fontSize}px</span>
                </div>
                <input
                  type="range"
                  min="12"
                  max="24"
                  step="1"
                  value={settings.fontSize}
                  onChange={(e) => handleSettingChange('fontSize', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Small</span>
                  <span>Large</span>
                </div>
              </div>
              
              {/* Font Weight */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Font Weight</h3>
                  <span className="text-sm text-gray-500">{settings.fontWeight}</span>
                </div>
                <input
                  type="range"
                  min="300"
                  max="700"
                  step="100"
                  value={settings.fontWeight}
                  onChange={(e) => handleSettingChange('fontWeight', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Light</span>
                  <span>Bold</span>
                </div>
              </div>
              
              {/* Shadow Depth */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Shadow Depth</h3>
                  <span className="text-sm text-gray-500">{settings.shadowDepth}</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="5"
                  step="1"
                  value={settings.shadowDepth}
                  onChange={(e) => handleSettingChange('shadowDepth', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>None</span>
                  <span>Deep</span>
                </div>
              </div>
              
              {/* Border Radius */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Border Radius</h3>
                  <span className="text-sm text-gray-500">{settings.borderRadius}px</span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="30"
                  step="2"
                  value={settings.borderRadius}
                  onChange={(e) => handleSettingChange('borderRadius', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Square</span>
                  <span>Rounded</span>
                </div>
              </div>
              
              {/* Card Padding */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">Card Padding</h3>
                  <span className="text-sm text-gray-500">{settings.cardPadding}px</span>
                </div>
                <input
                  type="range"
                  min="4"
                  max="16"
                  step="2"
                  value={settings.cardPadding}
                  onChange={(e) => handleSettingChange('cardPadding', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Compact</span>
                  <span>Spacious</span>
                </div>
              </div>
              
              {/* List Padding */}
              <div className="mb-6">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-lg font-medium">List Padding</h3>
                  <span className="text-sm text-gray-500">{settings.listPadding}px</span>
                </div>
                <input
                  type="range"
                  min="4"
                  max="16"
                  step="2"
                  value={settings.listPadding}
                  onChange={(e) => handleSettingChange('listPadding', parseInt(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-xs text-gray-500 mt-1">
                  <span>Compact</span>
                  <span>Spacious</span>
                </div>
              </div>
              
              {/* Buttons */}
              <div className="mt-8 flex justify-between">
                <button
                  onClick={() => setIsSettingsOpen(false)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors font-medium"
                >
                  Save
                </button>
                <button
                  onClick={resetSettings}
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors font-medium"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}
        
        <DragDropContext onDragEnd={handleDragEnd}>
          <div 
            className="flex flex-wrap gap-4 pb-4 items-start"
            style={{ 
              minHeight: '100px',
              alignItems: 'flex-start',
              ...getBoardContainerStyle()
            }}
          >
            {lists.map((list, index) => (
              <Droppable 
                key={`droppable-${index}`} 
                droppableId={`list-${index}`} 
                type="list"
                direction="horizontal"
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      padding: snapshot.isDraggingOver ? '4px' : '0px',
                      borderRadius: '4px',
                      backgroundColor: snapshot.isDraggingOver ? 'rgba(173, 216, 230, 0.1)' : 'transparent',
                      width: '250px',
                      minWidth: '250px',
                      maxWidth: '250px',
                      minHeight: '50px'
                    }}
                  >
                    <List 
                      key={list.id}
                      list={list}
                      index={0} // Always index 0 since each droppable has only one item
                      addCard={addCard}
                      deleteList={deleteListHandler}
                      deleteCard={deleteCardHandler}
                      updateListTitle={updateListTitle}
                      updateListColor={updateListColor}
                      updateCardContent={updateCardContent}
                      updateCardDeadline={updateCardDeadline}
                      fontSize={getFontSizeClass()}
                      fontWeight={getFontWeightClass()}
                      shadowDepth={settings.shadowDepth}
                      borderRadius={settings.borderRadius}
                      cardPadding={settings.cardPadding}
                      listPadding={settings.listPadding}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
            
            <div className="h-fit" style={{ minWidth: '272px' }}>
              <AddListForm 
                onAddList={addList}
                fontSize={getFontSizeClass()}
                fontWeight={getFontWeightClass()}
              />
            </div>
          </div>
        </DragDropContext>
      </div>
    </>
  );
}
