import React from 'react';

export default function AuthDebug() {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-md mt-10">
      <h1 className="text-2xl font-bold mb-4">Auth Debug Information</h1>
      <p>If you can see this, the AuthDebug component is rendering correctly.</p>
      
      <div className="mt-4 p-4 bg-gray-100 rounded">
        <h2 className="text-xl font-semibold mb-2">Basic Information</h2>
        <p><strong>Window Location:</strong> {window.location.href}</p>
        <p><strong>Origin:</strong> {window.location.origin}</p>
        <p><strong>Pathname:</strong> {window.location.pathname}</p>
      </div>
    </div>
  );
} 