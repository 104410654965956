import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export default function DeadlinePicker({ 
  currentDeadline, 
  onDeadlineChange, 
  onClose,
  fontSize,
  fontWeight,
  menuButtonRef
}) {
  const [selectedDate, setSelectedDate] = useState(
    currentDeadline ? new Date(currentDeadline).toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
  );
  const [selectedTime, setSelectedTime] = useState(
    currentDeadline 
      ? new Date(currentDeadline).toTimeString().slice(0, 5) 
      : '12:00'
  );
  const containerRef = useRef(null);

  // Handle outside click to close the picker
  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        onClose();
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  // Quick selection options
  const quickOptions = [
    { 
      label: 'Today', 
      value: () => {
        const today = new Date();
        today.setHours(23, 59, 59);
        return {
          date: today.toISOString().split('T')[0],
          time: '23:59'
        };
      }
    },
    { 
      label: 'Tomorrow', 
      value: () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(23, 59, 59);
        return {
          date: tomorrow.toISOString().split('T')[0],
          time: '23:59'
        };
      }
    },
    { 
      label: 'Next Week', 
      value: () => {
        const nextWeek = new Date();
        nextWeek.setDate(nextWeek.getDate() + 7);
        nextWeek.setHours(23, 59, 59);
        return {
          date: nextWeek.toISOString().split('T')[0],
          time: '23:59'
        };
      }
    }
  ];

  // Handle saving the deadline
  const handleSave = () => {
    const [year, month, day] = selectedDate.split('-').map(Number);
    const [hours, minutes] = selectedTime.split(':').map(Number);
    
    const dateTime = new Date(year, month - 1, day, hours, minutes);
    onDeadlineChange(dateTime.toISOString());
    onClose();
  };

  // Handle removing the deadline
  const handleRemove = () => {
    onDeadlineChange(null);
    onClose();
  };

  return createPortal(
    <div 
      ref={containerRef}
      className="bg-white rounded-md shadow-lg p-4 w-72"
      style={{
        position: 'fixed',
        top: menuButtonRef?.current ? menuButtonRef.current.getBoundingClientRect().bottom + 8 : 'auto',
        left: menuButtonRef?.current ? menuButtonRef.current.getBoundingClientRect().left - 160 : 'auto',
        zIndex: 3500
      }}
    >
      <div className="mb-4">
        <h3 className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>
          Quick Select
        </h3>
        <div className="flex space-x-2">
          {quickOptions.map((option) => (
            <button
              key={option.label}
              onClick={() => {
                const { date, time } = option.value();
                setSelectedDate(date);
                setSelectedTime(time);
              }}
              className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
              style={{ ...fontSize, ...fontWeight }}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
      
      <div className="mb-4">
        <h3 className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>
          Date
        </h3>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded text-sm"
        />
      </div>
      
      <div className="mb-4">
        <h3 className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>
          Time
        </h3>
        <input
          type="time"
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded text-sm"
        />
      </div>
      
      <div className="flex justify-between">
        <button
          onClick={handleRemove}
          className="px-3 py-1 text-sm text-red-500 hover:text-red-700 transition-colors"
          style={{ ...fontSize, ...fontWeight }}
        >
          Remove
        </button>
        
        <div className="flex space-x-2">
          <button
            onClick={onClose}
            className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
            style={{ ...fontSize, ...fontWeight }}
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-3 py-1 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors"
            style={{ ...fontSize, ...fontWeight }}
          >
            Save
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
} 