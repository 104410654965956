import { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';

function EnvTest() {
  const [envInfo, setEnvInfo] = useState({
    mode: import.meta.env.MODE || 'unknown',
    supabaseUrlFromEnv: !!import.meta.env.VITE_SUPABASE_URL,
    supabaseAnonKeyFromEnv: !!import.meta.env.VITE_SUPABASE_ANON_KEY,
    runtimeConfigExists: false,
    supabaseUrlFromRuntime: false,
    supabaseAnonKeyFromRuntime: false,
    supabaseClientCreated: false,
    supabaseError: null,
    testConnectionResult: null
  });
  
  useEffect(() => {
    // Check runtime config
    const runtimeConfig = typeof window !== 'undefined' && window.ENV_CONFIG ? window.ENV_CONFIG : {};
    
    // Test Supabase connection
    const testConnection = async () => {
      try {
        const { data, error } = await supabase.from('cards').select('id').limit(1);
        
        if (error) {
          return { success: false, message: error.message };
        }
        
        return { 
          success: true, 
          message: 'Successfully connected to Supabase',
          data: data ? `Found ${data.length} records` : 'No records found'
        };
      } catch (err) {
        return { success: false, message: err.message };
      }
    };
    
    const runTests = async () => {
      const testResult = await testConnection();
      
      setEnvInfo({
        mode: import.meta.env.MODE || 'unknown',
        supabaseUrlFromEnv: !!import.meta.env.VITE_SUPABASE_URL,
        supabaseAnonKeyFromEnv: !!import.meta.env.VITE_SUPABASE_ANON_KEY,
        runtimeConfigExists: !!runtimeConfig,
        supabaseUrlFromRuntime: !!runtimeConfig.SUPABASE_URL,
        supabaseAnonKeyFromRuntime: !!runtimeConfig.SUPABASE_ANON_KEY,
        supabaseClientCreated: !!supabase,
        supabaseError: null,
        testConnectionResult: testResult
      });
    };
    
    runTests();
  }, []);
  
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Environment Variable Test</h1>
      
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <h2 className="text-xl font-semibold mb-4">Environment Information</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="border rounded p-4">
            <h3 className="font-medium">Build Environment</h3>
            <ul className="mt-2 space-y-2">
              <li>
                <span className="font-medium">Mode: </span>
                <span className="px-2 py-1 bg-gray-100 rounded">{envInfo.mode}</span>
              </li>
              <li>
                <span className="font-medium">VITE_SUPABASE_URL: </span>
                {envInfo.supabaseUrlFromEnv ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Defined</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Missing</span>
                )}
              </li>
              <li>
                <span className="font-medium">VITE_SUPABASE_ANON_KEY: </span>
                {envInfo.supabaseAnonKeyFromEnv ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Defined</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Missing</span>
                )}
              </li>
            </ul>
          </div>
          
          <div className="border rounded p-4">
            <h3 className="font-medium">Runtime Configuration</h3>
            <ul className="mt-2 space-y-2">
              <li>
                <span className="font-medium">window.ENV_CONFIG: </span>
                {envInfo.runtimeConfigExists ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Exists</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Missing</span>
                )}
              </li>
              <li>
                <span className="font-medium">SUPABASE_URL: </span>
                {envInfo.supabaseUrlFromRuntime ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Defined</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Missing</span>
                )}
              </li>
              <li>
                <span className="font-medium">SUPABASE_ANON_KEY: </span>
                {envInfo.supabaseAnonKeyFromRuntime ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Defined</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Missing</span>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4">Supabase Connection Test</h2>
        <div className="border rounded p-4">
          <h3 className="font-medium">Client Status</h3>
          <ul className="mt-2 space-y-2">
            <li>
              <span className="font-medium">Supabase Client: </span>
              {envInfo.supabaseClientCreated ? (
                <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Created</span>
              ) : (
                <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Failed</span>
              )}
            </li>
            {envInfo.testConnectionResult && (
              <li>
                <span className="font-medium">Connection Test: </span>
                {envInfo.testConnectionResult.success ? (
                  <span className="px-2 py-1 bg-green-100 text-green-800 rounded">Success</span>
                ) : (
                  <span className="px-2 py-1 bg-red-100 text-red-800 rounded">Failed</span>
                )}
              </li>
            )}
            {envInfo.testConnectionResult && (
              <li>
                <span className="font-medium">Result: </span>
                <span className="px-2 py-1 bg-gray-100 rounded">{envInfo.testConnectionResult.message}</span>
              </li>
            )}
            {envInfo.testConnectionResult && envInfo.testConnectionResult.data && (
              <li>
                <span className="font-medium">Data: </span>
                <span className="px-2 py-1 bg-gray-100 rounded">{envInfo.testConnectionResult.data}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      
      <div className="mt-6 p-4 bg-blue-50 border border-blue-200 rounded-lg">
        <h2 className="text-lg font-semibold text-blue-800 mb-2">Troubleshooting Steps</h2>
        <ol className="list-decimal pl-5 space-y-2 text-blue-800">
          <li>Check that environment variables are set correctly in Netlify (Site settings &gt; Build &amp; deploy &gt; Environment)</li>
          <li>Ensure both VITE_SUPABASE_URL and VITE_SUPABASE_ANON_KEY are marked as "Build" variables</li>
          <li>Update the public/env-config.js file with your actual Supabase credentials</li>
          <li>Check the browser console for any JavaScript errors</li>
          <li>Try clearing your browser cache and reloading the page</li>
        </ol>
      </div>
    </div>
  );
}

export default EnvTest;
