import { supabase } from '../supabaseClient';

/**
 * Check if the user_settings table exists in the database
 * @returns {Promise<boolean>} True if the table exists, false otherwise
 */
export const checkUserSettingsTable = async () => {
  try {
    // Query the information_schema to check if the table exists
    const { data, error } = await supabase
      .from('information_schema.tables')
      .select('table_name')
      .eq('table_schema', 'public')
      .eq('table_name', 'user_settings');
    
    if (error) {
      console.error('Error checking user_settings table:', error);
      return false;
    }
    
    return data && data.length > 0;
  } catch (error) {
    console.error('Exception checking user_settings table:', error);
    return false;
  }
};

/**
 * Check if the board_name column exists in the user_settings table
 * @returns {Promise<boolean>} True if the column exists, false otherwise
 */
export const checkBoardNameColumn = async () => {
  try {
    // Query the information_schema to check if the column exists
    const { data, error } = await supabase
      .from('information_schema.columns')
      .select('column_name')
      .eq('table_schema', 'public')
      .eq('table_name', 'user_settings')
      .eq('column_name', 'board_name');
    
    if (error) {
      console.error('Error checking board_name column:', error);
      return false;
    }
    
    return data && data.length > 0;
  } catch (error) {
    console.error('Exception checking board_name column:', error);
    return false;
  }
};

/**
 * Create the user_settings table if it doesn't exist
 * @returns {Promise<boolean>} True if successful, false otherwise
 */
export const createUserSettingsTable = async () => {
  try {
    // Create the user_settings table
    const { error } = await supabase.rpc('create_user_settings_table');
    
    if (error) {
      console.error('Error creating user_settings table:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Exception creating user_settings table:', error);
    return false;
  }
};

/**
 * Add the board_name column to the user_settings table if it doesn't exist
 * @returns {Promise<boolean>} True if successful, false otherwise
 */
export const addBoardNameColumn = async () => {
  try {
    // Add the board_name column
    const { error } = await supabase.rpc('add_board_name_column');
    
    if (error) {
      console.error('Error adding board_name column:', error);
      return false;
    }
    
    return true;
  } catch (error) {
    console.error('Exception adding board_name column:', error);
    return false;
  }
};

/**
 * Run all checks and create missing structures if needed
 * @returns {Promise<Object>} Results of the checks and operations
 */
export const checkAndFixDatabaseStructure = async () => {
  const results = {
    tableExists: false,
    columnExists: false,
    tableCreated: false,
    columnAdded: false
  };
  
  // Check if the table exists
  results.tableExists = await checkUserSettingsTable();
  
  // If the table doesn't exist, create it
  if (!results.tableExists) {
    results.tableCreated = await createUserSettingsTable();
    if (results.tableCreated) {
      results.tableExists = true;
    }
  }
  
  // If the table exists, check if the column exists
  if (results.tableExists) {
    results.columnExists = await checkBoardNameColumn();
    
    // If the column doesn't exist, add it
    if (!results.columnExists) {
      results.columnAdded = await addBoardNameColumn();
      if (results.columnAdded) {
        results.columnExists = true;
      }
    }
  }
  
  return results;
}; 