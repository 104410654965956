import { useState } from 'react';

export default function AddCardForm({ onSubmit, onCancel, fontSize, fontWeight }) {
  const [content, setContent] = useState('');

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (content.trim()) {
      onSubmit(content.trim());
      setContent('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    } else if (e.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-2">
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Enter card content..."
        className="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
        style={{
          ...fontSize,
          ...fontWeight,
          minHeight: '60px'
        }}
        autoFocus
      />
      <div className="flex items-center">
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1.5 rounded transition-colors"
          style={{
            ...fontSize,
            ...fontWeight
          }}
        >
          Add Card
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="ml-2 text-gray-600 hover:text-gray-800"
          style={{
            ...fontSize,
            ...fontWeight
          }}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}
