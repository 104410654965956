import { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import * as dbCheck from '../utils/checkDatabaseStructure';

export default function DatabaseCheck() {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [manualSqlResult, setManualSqlResult] = useState(null);
  const [sqlLoading, setSqlLoading] = useState(false);

  useEffect(() => {
    // Check if user is authenticated
    const checkAuth = async () => {
      const { data } = await supabase.auth.getUser();
      setUser(data.user);
      
      if (data.user) {
        runChecks();
      } else {
        setLoading(false);
        setError('You must be logged in to check the database structure.');
      }
    };
    
    checkAuth();
  }, []);

  const runChecks = async () => {
    try {
      setLoading(true);
      const checkResults = await dbCheck.checkAndFixDatabaseStructure();
      setResults(checkResults);
    } catch (err) {
      setError(err.message || 'An error occurred while checking the database structure.');
    } finally {
      setLoading(false);
    }
  };

  const runManualSql = async () => {
    try {
      setSqlLoading(true);
      
      // First, check if the table exists
      const { data: tableExists, error: tableError } = await supabase.rpc(
        'execute_sql',
        { 
          sql_query: `
            SELECT EXISTS (
              SELECT FROM information_schema.tables 
              WHERE table_schema = 'public'
              AND table_name = 'user_settings'
            );
          `
        }
      );
      
      if (tableError) throw new Error(`Table check error: ${tableError.message}`);
      
      let columnExists = false;
      
      // If table exists, check if column exists
      if (tableExists && tableExists[0] && tableExists[0].exists) {
        const { data: columnData, error: columnError } = await supabase.rpc(
          'execute_sql',
          { 
            sql_query: `
              SELECT EXISTS (
                SELECT FROM information_schema.columns 
                WHERE table_schema = 'public'
                AND table_name = 'user_settings'
                AND column_name = 'board_name'
              );
            `
          }
        );
        
        if (columnError) throw new Error(`Column check error: ${columnError.message}`);
        columnExists = columnData && columnData[0] && columnData[0].exists;
      }
      
      // Create table if it doesn't exist
      if (!tableExists || !tableExists[0] || !tableExists[0].exists) {
        const { error: createError } = await supabase.rpc(
          'execute_sql',
          { 
            sql_query: `
              CREATE TABLE IF NOT EXISTS user_settings (
                id BIGSERIAL PRIMARY KEY,
                user_id UUID REFERENCES auth.users(id) UNIQUE,
                background_color TEXT DEFAULT '#DDDDDD',
                font_size INTEGER DEFAULT 18,
                font_weight INTEGER DEFAULT 500,
                shadow_depth INTEGER DEFAULT 2,
                border_radius INTEGER DEFAULT 6,
                card_padding INTEGER DEFAULT 10,
                list_padding INTEGER DEFAULT 8,
                background_image_url TEXT,
                board_name TEXT DEFAULT 'My Board',
                created_at TIMESTAMP WITH TIME ZONE DEFAULT NOW()
              );
              
              -- Enable RLS on user_settings
              ALTER TABLE user_settings ENABLE ROW LEVEL SECURITY;
              
              -- Create policy for user_settings
              CREATE POLICY IF NOT EXISTS "Users can CRUD their own settings" ON user_settings
                USING (user_id = auth.uid())
                WITH CHECK (user_id = auth.uid());
            `
          }
        );
        
        if (createError) throw new Error(`Table creation error: ${createError.message}`);
      }
      
      // Add column if it doesn't exist
      if ((tableExists && tableExists[0] && tableExists[0].exists) && !columnExists) {
        const { error: alterError } = await supabase.rpc(
          'execute_sql',
          { 
            sql_query: `
              ALTER TABLE user_settings 
              ADD COLUMN IF NOT EXISTS board_name TEXT DEFAULT 'My Board';
            `
          }
        );
        
        if (alterError) throw new Error(`Column addition error: ${alterError.message}`);
      }
      
      // Final check to verify everything is set up correctly
      const { data: finalCheck, error: finalError } = await supabase.rpc(
        'execute_sql',
        { 
          sql_query: `
            SELECT 
              EXISTS (
                SELECT FROM information_schema.tables 
                WHERE table_schema = 'public'
                AND table_name = 'user_settings'
              ) as table_exists,
              EXISTS (
                SELECT FROM information_schema.columns 
                WHERE table_schema = 'public'
                AND table_name = 'user_settings'
                AND column_name = 'board_name'
              ) as column_exists;
          `
        }
      );
      
      if (finalError) throw new Error(`Final check error: ${finalError.message}`);
      
      setManualSqlResult({
        initialTableExists: tableExists && tableExists[0] && tableExists[0].exists,
        initialColumnExists: columnExists,
        finalTableExists: finalCheck && finalCheck[0] && finalCheck[0].table_exists,
        finalColumnExists: finalCheck && finalCheck[0] && finalCheck[0].column_exists
      });
      
    } catch (err) {
      setError(err.message || 'An error occurred while running manual SQL.');
      setManualSqlResult({ error: err.message });
    } finally {
      setSqlLoading(false);
    }
  };

  if (!user) {
    return (
      <div className="p-8">
        <h1 className="text-2xl font-bold mb-4">Database Structure Check</h1>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4">
          You must be logged in to check the database structure.
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Database Structure Check</h1>
      
      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4">
          {error}
        </div>
      )}
      
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Automatic Checks</h2>
        {loading ? (
          <div className="text-gray-600">Running checks...</div>
        ) : results ? (
          <div className="bg-white shadow-md rounded p-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="border-b pb-2">
                <span className="font-medium">Table Exists:</span> 
                <span className={results.tableExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {results.tableExists ? "Yes" : "No"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Column Exists:</span> 
                <span className={results.columnExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {results.columnExists ? "Yes" : "No"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Table Created:</span> 
                <span className={results.tableCreated ? "text-green-600 ml-2" : "text-gray-600 ml-2"}>
                  {results.tableCreated ? "Yes" : "Not needed"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Column Added:</span> 
                <span className={results.columnAdded ? "text-green-600 ml-2" : "text-gray-600 ml-2"}>
                  {results.columnAdded ? "Yes" : "Not needed"}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <button 
            onClick={runChecks}
            className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
          >
            Run Checks
          </button>
        )}
      </div>
      
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Manual SQL Fix</h2>
        {sqlLoading ? (
          <div className="text-gray-600">Running SQL...</div>
        ) : manualSqlResult ? (
          <div className="bg-white shadow-md rounded p-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="border-b pb-2">
                <span className="font-medium">Initial Table Exists:</span> 
                <span className={manualSqlResult.initialTableExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {manualSqlResult.initialTableExists ? "Yes" : "No"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Initial Column Exists:</span> 
                <span className={manualSqlResult.initialColumnExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {manualSqlResult.initialColumnExists ? "Yes" : "No"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Final Table Exists:</span> 
                <span className={manualSqlResult.finalTableExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {manualSqlResult.finalTableExists ? "Yes" : "No"}
                </span>
              </div>
              <div className="border-b pb-2">
                <span className="font-medium">Final Column Exists:</span> 
                <span className={manualSqlResult.finalColumnExists ? "text-green-600 ml-2" : "text-red-600 ml-2"}>
                  {manualSqlResult.finalColumnExists ? "Yes" : "No"}
                </span>
              </div>
              {manualSqlResult.error && (
                <div className="col-span-2 text-red-600">
                  Error: {manualSqlResult.error}
                </div>
              )}
            </div>
          </div>
        ) : (
          <button 
            onClick={runManualSql}
            className="bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded"
          >
            Run Manual SQL Fix
          </button>
        )}
      </div>
      
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-2">Next Steps</h2>
        <p className="text-gray-700 mb-4">
          After fixing the database structure, try saving settings again to see if the issue is resolved.
        </p>
        <a 
          href="/"
          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded inline-block"
        >
          Return to Board
        </a>
      </div>
    </div>
  );
} 