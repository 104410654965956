import { useState } from 'react';

export default function AddListForm({ onAddList, fontSize, fontWeight }) {
  const [title, setTitle] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title.trim()) return;
    
    onAddList(title);
    setTitle('');
    setIsEditing(false);
  };

  if (!isEditing) {
    return (
      <div className="flex items-center justify-center h-10 w-10 rounded-full hover:bg-gray-200 transition-all duration-300 opacity-50 hover:opacity-100"
           onClick={() => setIsEditing(true)}
           style={{
             backgroundColor: '#DEDEDE'
           }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
        </svg>
      </div>
    );
  }

  return (
    <div className="w-[272px] bg-[#DEDEDE] rounded-md p-2">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter list title..."
          className="w-full p-2 border border-gray-300 rounded mb-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          style={{
            ...fontSize,
            ...fontWeight
          }}
          autoFocus
        />
        
        <div className="flex space-x-2">
          <button
            type="submit"
            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            style={{
              ...fontSize,
              ...fontWeight
            }}
          >
            Add List
          </button>
          <button
            type="button"
            onClick={() => setIsEditing(false)}
            className="px-3 py-1 text-gray-600 hover:bg-gray-200 rounded transition-colors"
            style={{
              ...fontSize,
              ...fontWeight
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
