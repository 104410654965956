import { useState, useRef, useEffect } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { createPortal } from 'react-dom';
import DeadlinePicker from './DeadlinePicker';

export default function Card({ 
  card, 
  index, 
  listId, 
  deleteCard, 
  updateCardContent,
  updateCardDeadline,
  fontSize, 
  fontWeight, 
  shadowDepth = 2,
  borderRadius = 4,
  cardPadding = 8,
  listWidth = 234 // Default width if not provided (250px list - 16px padding)
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(card.content);
  const [isDone, setIsDone] = useState(card.isDone || false);
  const editInputRef = useRef(null);
  const menuButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  
  // Add state to track if dropdown should appear above
  const [showAbove, setShowAbove] = useState(false);
  
  // Add these new states for the countdown
  const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0, minutes: 0 });
  const [isPastDeadline, setIsPastDeadline] = useState(false);
  
  // Add state for DeadlinePicker
  const [isDeadlinePickerOpen, setIsDeadlinePickerOpen] = useState(false);
  
  // Focus the input when editing starts
  useEffect(() => {
    if (isEditing && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [isEditing]);
  
  // Add effect to check dropdown position
  useEffect(() => {
    if (isMenuOpen && menuButtonRef.current && dropdownRef.current) {
      const buttonRect = menuButtonRef.current.getBoundingClientRect();
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const viewportHeight = window.innerHeight;
      
      // If there's not enough space below, show above
      if (buttonRect.bottom + dropdownHeight > viewportHeight - 20) {
        setShowAbove(true);
      } else {
        setShowAbove(false);
      }
    }
  }, [isMenuOpen]);
  
  // Add this new effect for calculating the countdown
  useEffect(() => {
    if (!card.deadline) return;
    
    const calculateTimeRemaining = () => {
      const now = new Date();
      const deadlineDate = new Date(card.deadline);
      const difference = deadlineDate - now;
      
      if (difference <= 0) {
        setIsPastDeadline(true);
        return;
      }
      
      setIsPastDeadline(false);
      
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      
      setTimeRemaining({ days, hours, minutes });
    };
    
    // Calculate immediately
    calculateTimeRemaining();
    
    // Then update every minute
    const timer = setInterval(calculateTimeRemaining, 60000);
    
    return () => clearInterval(timer);
  }, [card.deadline]);
  
  // Toggle the done state when the deadline tag is clicked
  const toggleDone = (e) => {
    e.stopPropagation(); // Prevent card from going into edit mode
    const newDoneState = !isDone;
    setIsDone(newDoneState);
    
    // Update the card in the database with the new isDone state
    // We're assuming updateCardContent can handle this additional property
    // If not, you might need to create a new function like updateCardDoneState
    updateCardContent(card.id, listId.toString(), card.content, { isDone: newDoneState });
  };
  
  // Handle saving the edited content
  const handleSaveEdit = () => {
    if (editedContent.trim() !== card.content) {
      // Convert listId to string for consistency
      // Pass the current isDone state to preserve it
      updateCardContent(card.id, listId.toString(), editedContent, { isDone });
    }
    setIsEditing(false);
  };
  
  // Handle key press events in the edit input
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSaveEdit();
    } else if (e.key === 'Escape') {
      setEditedContent(card.content); // Reset to original content
      setIsEditing(false);
    }
  };
  
  return (
    <Draggable draggableId={card.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`relative mb-2 bg-white rounded-md ${snapshot.isDragging ? 'shadow-lg' : ''}`}
          style={{
            ...provided.draggableProps.style,
            borderRadius: `${borderRadius}px`,
            padding: `${cardPadding}px`,
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // 90% opacity for card background
            boxShadow: shadowDepth === 0 ? 'none' : `0 ${shadowDepth}px ${shadowDepth * 2}px rgba(0,0,0,${0.15 + (shadowDepth * 0.05)})`,
            width: `${listWidth}px`, // Use the listWidth prop for consistent width
            maxWidth: `${listWidth}px`, // Ensure max-width matches width
            overflowWrap: 'break-word', // Ensure text wraps naturally
            wordWrap: 'break-word', // For older browsers
            wordBreak: 'normal', // Prevent breaking words in the middle
            zIndex: 500 // Added z-index for card elements
          }}
        >
          {/* Menu button */}
          <button
            ref={menuButtonRef}
            onClick={(e) => {
              e.stopPropagation();
              setIsMenuOpen(!isMenuOpen);
            }}
            className="absolute top-2 right-1 text-gray-400 hover:text-gray-600 focus:outline-none"
            style={{ opacity: 0.5 }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
            </svg>
          </button>
          
          {/* Menu dropdown */}
          {isMenuOpen && createPortal(
            <div 
              ref={dropdownRef}
              className="bg-white rounded-md shadow-lg py-1"
              style={{
                position: 'fixed',
                left: menuButtonRef.current ? menuButtonRef.current.getBoundingClientRect().right - 192 : 'auto',
                top: showAbove ? 'auto' : (menuButtonRef.current ? menuButtonRef.current.getBoundingClientRect().bottom + 8 : 0),
                bottom: showAbove ? (menuButtonRef.current ? window.innerHeight - menuButtonRef.current.getBoundingClientRect().top + 8 : 0) : 'auto',
                width: '192px',
                zIndex: 2500
              }}
            >
              <button 
                onClick={() => {
                  setIsMenuOpen(false);
                  setIsEditing(true);
                }}
                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                style={{
                  ...fontSize,
                  ...fontWeight
                }}
              >
                Edit
              </button>
              
              {/* Deadline section */}
              <div className="px-4 py-2 border-t border-gray-100">
                <p className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>Deadline</p>
                <button
                  onClick={() => {
                    setIsMenuOpen(false);
                    setIsDeadlinePickerOpen(true);
                  }}
                  className="w-full p-2 text-left text-sm bg-gray-100 hover:bg-gray-200 rounded transition-colors"
                  style={{ ...fontSize, ...fontWeight }}
                >
                  {card.deadline 
                    ? new Date(card.deadline).toLocaleString(undefined, {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                      })
                    : 'Set deadline'}
                </button>
              </div>
              
              <button 
                onClick={() => {
                  setIsMenuOpen(false);
                  deleteCard(card.id, listId.toString());
                }}
                className="block w-full text-left px-4 py-2 text-red-600 hover:bg-gray-100"
                style={{
                  ...fontSize,
                  ...fontWeight
                }}
              >
                Delete
              </button>
            </div>,
            document.body
          )}
          
          {/* Deadline Picker */}
          {isDeadlinePickerOpen && (
            <DeadlinePicker
              currentDeadline={card.deadline}
              onDeadlineChange={(newDeadline) => {
                updateCardDeadline(card.id, listId, newDeadline);
              }}
              onClose={() => setIsDeadlinePickerOpen(false)}
              fontSize={fontSize}
              fontWeight={fontWeight}
              menuButtonRef={menuButtonRef}
            />
          )}
          
          {/* Add countdown display */}
          <div className="flex justify-between items-start">
            {/* Card content */}
            {isEditing ? (
              <textarea
                ref={editInputRef}
                value={editedContent}
                onChange={(e) => setEditedContent(e.target.value)}
                onBlur={handleSaveEdit}
                onKeyDown={handleKeyDown}
                className="w-full pt-1 pb-1 pl-0 pr-0 border border-blue-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                style={{
                  minHeight: '60px',
                  maxWidth: '100%', // Ensure textarea respects parent width
                  ...fontSize,
                  ...fontWeight
                }}
                autoFocus
              />
            ) : (
              <div 
                onClick={() => setIsEditing(true)}
                className="cursor-pointer whitespace-pre-wrap break-words flex-grow"
                style={{
                  ...fontSize,
                  ...fontWeight,
                  wordBreak: 'normal', // Prevent breaking words in the middle
                  overflowWrap: 'break-word' // Ensure text wraps naturally
                }}
              >
                {card.content}
              </div>
            )}
            
            {/* Deadline tag */}
            {card.deadline && (
              <div className="ml-2 flex-shrink-0" style={{ marginRight: '12px' }}>
                <div 
                  onClick={toggleDone}
                  className={`text-xs px-2 py-0.5 rounded-full cursor-pointer ${
                    isDone 
                      ? 'bg-green-100 text-green-700' 
                      : isPastDeadline 
                        ? 'bg-red-100 text-red-600' 
                        : 'bg-gray-200 text-gray-500'
                  }`}
                  style={{ 
                    fontSize: fontSize.fontSize ? `${parseInt(fontSize.fontSize) * 0.8}px` : '0.7rem', // Make tag font smaller than page font
                    backgroundColor: isDone 
                      ? '#E3F9E5' // Light green background
                      : isPastDeadline || (timeRemaining.days === 0 && timeRemaining.hours < 24)
                        ? '#FFE2E2' 
                        : '#e8e8e8',
                    color: isDone 
                      ? '#2F855A' // Dark green text
                      : isPastDeadline || (timeRemaining.days === 0 && timeRemaining.hours < 24)
                        ? '#FF6666' 
                        : '#666666',
                    fontWeight: (fontWeight.fontWeight ? parseInt(fontWeight.fontWeight) + 100 : 400)
                  }}
                >
                  {isDone 
                    ? (
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="inline" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor" 
                        strokeWidth={3}
                        style={{ 
                          height: fontSize.fontSize ? `${parseInt(fontSize.fontSize) * 0.8}px` : '0.7rem',
                          width: fontSize.fontSize ? `${parseInt(fontSize.fontSize) * 0.8}px` : '0.7rem'
                        }}
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    )
                    : isPastDeadline 
                      ? 'Overdue' 
                      : timeRemaining.days > 0 
                        ? `${timeRemaining.days}d` 
                        : timeRemaining.hours > 0 
                          ? `${timeRemaining.hours}h` 
                          : `${timeRemaining.minutes}m`
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}
