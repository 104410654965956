import { useState, useEffect, useRef } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import Card from './Card';
import AddCardForm from './AddCardForm';

export default function List({ 
  list, 
  index, 
  addCard, 
  deleteList, 
  deleteCard, 
  updateListTitle,
  updateListColor,
  updateCardContent,
  updateCardDeadline,
  fontSize, 
  fontWeight, 
  shadowDepth = 2,
  borderRadius = 4,
  cardPadding = 8,
  listPadding = 8
}) {
  // Component state
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState(list.title);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);
  
  // Refs
  const menuRef = useRef(null);
  const contentRef = useRef(null);
  
  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Check if content overflows
  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        setHasOverflow(contentRef.current.scrollHeight > 300);
      }
    };

    checkOverflow();
    
    // Use ResizeObserver to detect content changes
    const resizeObserver = new ResizeObserver(checkOverflow);
    if (contentRef.current) resizeObserver.observe(contentRef.current);
    return () => {
      if (contentRef.current) resizeObserver.unobserve(contentRef.current);
    };
  }, [list.cards]);

  // Event handlers
  const handleTitleChange = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const trimmedTitle = e.target.value.trim();
      if (trimmedTitle) {
        updateListTitle(list.id, trimmedTitle);
        setIsEditingTitle(false);
      }
    } else if (e.key === 'Escape') {
      setIsEditingTitle(false);
    }
  };

  const handleTitleBlur = () => {
    const trimmedTitle = newTitle.trim();
    if (trimmedTitle) {
      updateListTitle(list.id, trimmedTitle);
    } else {
      setNewTitle(list.title);
    }
    setIsEditingTitle(false);
  };
  
  const handleAddCard = (content) => {
    addCard(content, list.id.toString());
    setIsEditing(false);
  };

  // Style generators
  const listStyle = {
    backgroundColor: list.backgroundColor || list.color || '#DEDEDE',
    boxShadow: `0 ${shadowDepth}px ${shadowDepth * 2}px rgba(0, 0, 0, 0.1)`,
    borderRadius: `${borderRadius}px`,
    padding: `${listPadding}px`,
    width: '250px',
    minWidth: '250px',
    maxWidth: '250px',
    opacity: 0.95,
    position: 'relative',
    zIndex: 50, // List container z-index
    marginRight: '8px',
    marginBottom: '8px'
  };

  const headerStyle = {
    backgroundColor: list.backgroundColor || list.color || '#DEDEDE',
    color: list.fontColor || '#333333',
    borderTopLeftRadius: `${borderRadius}px`,
    borderTopRightRadius: `${borderRadius}px`
  };

  const titleStyle = {
    fontSize: `${parseInt(fontSize.fontSize || '16') + 2}px`,
    fontWeight: parseInt(fontWeight.fontWeight || '500') + 100
  };

  const contentStyle = {
    maxHeight: isExpanded ? 'none' : '300px',
    width: '100%',
    position: 'relative',
    zIndex: 25, // Content area z-index
    overflow: isExpanded ? 'visible' : 'hidden'
  };
  
  // Render components
  const renderTitleInput = () => (
    <input
      type="text"
      value={newTitle}
      onChange={(e) => setNewTitle(e.target.value)}
      onKeyDown={handleTitleChange}
      onBlur={handleTitleBlur}
      className="w-full p-1 border border-gray-300 rounded"
      style={{ ...titleStyle, color: '#333333' }}
      autoFocus
    />
  );
  
  const renderTitle = () => (
    <h2
      className="font-semibold cursor-pointer truncate"
      style={titleStyle}
      onClick={() => setIsEditingTitle(true)}
    >
      {list.title}
    </h2>
  );
  
  const renderListMenu = () => (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-[1000] py-1">
      <button 
        onClick={() => { setIsMenuOpen(false); setIsEditingTitle(true); }}
        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
        style={{ ...fontSize, ...fontWeight }}
      >
        Edit Title
      </button>
      
      <div className="px-4 py-2">
        <p className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>List Background</p>
        <div className="flex flex-col">
          <input
            type="color"
            value={list.backgroundColor || list.color || '#F5F5F5'}
            onChange={(e) => updateListColor(list.id, e.target.value, list.fontColor || '#333333')}
            className="w-full h-8 rounded cursor-pointer mb-1"
            aria-label="Choose list background color"
          />
          <span className="text-xs text-gray-500 mt-1">
            {list.backgroundColor || list.color || '#F5F5F5'}
          </span>
        </div>
      </div>
      
      <div className="px-4 py-2">
        <p className="text-gray-700 font-medium mb-2" style={{ ...fontSize, ...fontWeight }}>Font Color</p>
        <div className="flex flex-col">
          <input
            type="color"
            value={list.fontColor || '#333333'}
            onChange={(e) => updateListColor(list.id, list.backgroundColor || list.color || '#F5F5F5', e.target.value)}
            className="w-full h-8 rounded cursor-pointer mb-1"
            aria-label="Choose list font color"
          />
          <span className="text-xs text-gray-500 mt-1">{list.fontColor || '#333333'}</span>
        </div>
      </div>
      
      <div className="flex space-x-2 px-4 py-2">
        <button 
          onClick={() => { updateListColor(list.id, '#DEDEDE', '#333333'); setIsMenuOpen(false); }}
          className="flex-1 px-3 py-1 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          style={{ ...fontSize, ...fontWeight }}
        >
          Reset
        </button>
        
        <button 
          onClick={() => { setIsMenuOpen(false); deleteList(list.id); }}
          className="flex-1 px-3 py-1 text-white bg-red-500 hover:bg-red-600 rounded-md transition-colors"
          style={{ ...fontSize, ...fontWeight }}
        >
          Delete
        </button>
      </div>
    </div>
  );
  
  const renderCards = (droppableProvided) => (
    <div
      ref={(el) => {
        droppableProvided.innerRef(el);
        contentRef.current = el;
      }}
      {...droppableProvided.droppableProps}
      className="min-h-[1px] mb-2 transition-all duration-300 ease-in-out"
      style={contentStyle}
    >
      {list.cards?.map((card, cardIndex) => (
        <Card
          key={card.id}
          card={card}
          index={cardIndex}
          listId={list.id.toString()}
          deleteCard={deleteCard}
          updateCardContent={updateCardContent}
          updateCardDeadline={updateCardDeadline}
          fontSize={fontSize}
          fontWeight={fontWeight}
          shadowDepth={shadowDepth}
          borderRadius={borderRadius}
          cardPadding={cardPadding}
          listWidth={234}
        />
      ))}
      {droppableProvided.placeholder}
    </div>
  );
  
  const renderToggleButton = () => {
    if (!hasOverflow) return null;
    
    return isExpanded ? (
      <button
        onClick={() => setIsExpanded(false)}
        className="w-full flex justify-center items-center py-0.5 text-gray-500 hover:text-gray-700 hover:bg-yellow-100 transition-colors rounded-md"
        title="Show less"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
        </svg>
      </button>
    ) : (
      <button
        onClick={() => setIsExpanded(true)}
        className="w-full flex justify-center items-center py-0.5 text-gray-500 hover:text-gray-700 hover:bg-yellow-100 transition-colors rounded-md"
        title="Show more"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
    );
  };
  
  const renderAddCardButton = () => (
    <button
      onClick={() => setIsEditing(true)}
      className="flex items-center w-full rounded p-2 pl-1 text-gray-600 hover:bg-yellow-100 hover:text-gray-800 transition-colors"
      style={{
        borderRadius: `${borderRadius}px`,
        ...fontSize,
        ...fontWeight,
        position: 'relative',
        zIndex: 150
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
      </svg>
      Add a card
    </button>
  );

  // Main component render
  return (
    <Draggable draggableId={list.id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="rounded-md shadow-md flex flex-col"
          style={{ 
            ...listStyle, 
            ...provided.draggableProps.style
          }}
        >
          {/* List Header */}
          <div 
            className="flex justify-between items-center mb-2 p-2 rounded-t"
            style={headerStyle}
            {...provided.dragHandleProps}
          >
            {isEditingTitle ? renderTitleInput() : renderTitle()}
            
            {/* List Menu Toggle */}
            <div className="relative" ref={menuRef}>
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
                style={{ ...fontSize, ...fontWeight, marginRight: '-3px' }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
                </svg>
              </button>
              
              {/* List Menu */}
              {isMenuOpen && renderListMenu()}
            </div>
          </div>

          {/* Card container */}
          <Droppable droppableId={list.id.toString()} type="card">
            {(provided) => renderCards(provided)}
          </Droppable>

          {/* Toggle Expand/Collapse button */}
          {renderToggleButton()}
          
          {/* Add Card Form or Button */}
          {isEditing ? (
            <div className="px-2">
              <AddCardForm 
                onSubmit={handleAddCard}
                onCancel={() => setIsEditing(false)}
                fontSize={fontSize}
                fontWeight={fontWeight}
              />
            </div>
          ) : renderAddCardButton()}
        </div>
      )}
    </Draggable>
  );
}
