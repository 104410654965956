/**
 * Storage Adapter
 * 
 * This module provides a unified interface for storage operations using Supabase.
 */

import * as supabaseDatabase from './supabaseDatabase';
import { supabase } from '../supabaseClient';

// Check if user is authenticated with Supabase
export const isAuthenticated = async () => {
  const { data } = await supabase.auth.getSession();
  return !!data.session;
};

// Lists operations
export const getLists = async () => {
  return supabaseDatabase.getLists();
};

export const saveList = async (list) => {
  return supabaseDatabase.saveList(list);
};

export const deleteList = async (listId) => {
  return supabaseDatabase.deleteList(listId);
};

export const updateListPositions = async (lists) => {
  return supabaseDatabase.updateListPositions(lists);
};

// Cards operations
export const getCards = async () => {
  return supabaseDatabase.getCards();
};

export const getCardsByListId = async (listId) => {
  return supabaseDatabase.getCardsByListId(listId);
};

export const saveCard = async (card) => {
  return supabaseDatabase.saveCard(card);
};

export const deleteCard = async (cardId) => {
  return supabaseDatabase.deleteCard(cardId);
};

export const updateCardPositions = async (cards) => {
  return supabaseDatabase.updateCardPositions(cards);
};

// Settings operations
export const getSettings = async () => {
  return supabaseDatabase.getSettings();
};

export const saveSettings = async (settings) => {
  return supabaseDatabase.saveSettings(settings);
};

// Background image operations
export const uploadBackgroundImage = async (file) => {
  return supabaseDatabase.uploadBackgroundImage(file);
};

export const deleteBackgroundImage = async () => {
  return supabaseDatabase.deleteBackgroundImage();
};
