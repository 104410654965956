import { useState } from 'react';
import { supabase } from '../supabaseClient';
import GoogleLogin from './GoogleLogin';

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [activeTab, setActiveTab] = useState('login'); // 'login' or 'about'

  const handleEmailLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({ 
        email,
        options: {
          emailRedirectTo: 'https://focus.cards/auth/callback',
        },
      });

      if (error) throw error;
      alert('Check your email for the login link!');
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Main Content */}
      <main className="flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-6xl w-full">
          {activeTab === 'login' ? (
            <div className="flex flex-col md:flex-row items-center justify-between gap-6">
              {/* Left side - Marketing Content */}
              <div className="w-full md:w-3/5 max-w-xl mx-auto">
                <div className="flex justify-center md:justify-start mb-6">
                  <img src="/weblogo.png" alt="FocusCards" className="h-16" />
                </div>
                <h2 className="text-4xl font-bold text-gray-900 mb-4 text-center md:text-left">Keep All Tasks Beautifully Organized in One Board.</h2>
                <p className="text-lg text-gray-600 mb-6">
                  FocusCards is a intuitive kanban board for task management that keeps everything beautifully organized in one place.
                </p>
                <div className="space-y-2">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <span className="text-xl">✅</span>
                    </div>
                    <p className="ml-3 text-gray-600">One-Board View — See all your lists at once</p>
                  </div>
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <span className="text-xl">✅</span>
                    </div>
                    <p className="ml-3 text-gray-600">Color-Schemed — Personalize background and color for visual clarity</p>
                  </div>
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <span className="text-xl">✅</span>
                    </div>
                    <p className="ml-3 text-gray-600">Deadline Tracking — See Important dates with visual countdowns</p>
                  </div>
                </div>
              </div>

              {/* Right side - Login Form */}
              <div className="w-full md:w-2/5 max-w-sm mx-auto">
                <div className="bg-white py-8 px-6 shadow rounded-lg">
                  <div className="text-center mb-6">
                    <h3 className="text-xl font-medium text-gray-900">Sign in to your account</h3>
                    <p className="mt-2 text-sm text-gray-600">
                      Get started organizing your tasks today
                    </p>
                  </div>

                  <div className="mt-6">
                    <GoogleLogin />
                  </div>

                  <div className="mt-6 relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white text-gray-500">Or sign in with email</span>
                    </div>
                  </div>

                  <form onSubmit={handleEmailLogin} className="mt-6 space-y-6">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email address
                      </label>
                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        placeholder="your.email@example.com"
                      />
                    </div>

                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        disabled={loading}
                      >
                        {loading ? 'Sending...' : 'Send Login Link'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-3xl mx-auto">
              <div className="bg-white shadow overflow-hidden rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex justify-center mb-4">
                    <img src="/weblogo.png" alt="FocusCards" className="h-16" />
                  </div>
                  <h3 className="text-2xl font-bold text-gray-900 text-center">About FocusCards</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500 text-center mx-auto">Keep All Tasks Beautifully Organized in One Board.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
                  <h4 className="text-lg font-medium text-gray-900 mb-4">Organize visually. Focus effortlessly. Accomplish more.</h4>
                  
                  <p className="text-gray-600 mb-6">
                    FocusCards is a intuitive kanban board for task management that keeps everything beautifully organized in one place.
                  </p>
                  
                  <h5 className="text-md font-medium text-gray-900 mb-3">Key Features</h5>
                  <ul className="list-disc pl-5 space-y-2 mb-6">
                    <li className="text-gray-600">
                      <span className="font-medium">Intuitive Visual Organization:</span> Arrange your tasks in customizable lists with drag-and-drop simplicity.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-medium">Seamless Cross-Device Experience:</span> Access your boards from any device with secure cloud storage.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-medium">Beautiful Customization:</span> Personalize your workspace with custom background images, adjustable fonts, colors, and card styles.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-medium">Secure Authentication:</span> Sign in easily with your Google account or magic link email authentication.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-medium">Flexible List Types:</span> Create specialized lists for different workflows - Priority Lists, Completed Tasks, In Progress items, or Blocked issues.
                    </li>
                    <li className="text-gray-600">
                      <span className="font-medium">Offline-to-Online Sync:</span> Work offline with local storage and seamlessly migrate to cloud storage when you're ready.
                    </li>
                  </ul>
                  
                  <div className="mt-8 text-center">
                    <button 
                      onClick={() => setActiveTab('login')}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Get Started Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-white border-t border-gray-200">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center">
              <p className="text-sm text-gray-500">© 2025 FocusCards. All rights reserved.</p>
            </div>
            <div className="mt-4 md:mt-0">
              <button 
                onClick={() => setActiveTab(activeTab === 'login' ? 'about' : 'login')}
                className="text-sm text-gray-500 hover:text-gray-700"
              >
                {activeTab === 'login' ? 'About FocusCards' : 'Back to Login'}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
